<template>
  <a-modal
    v-model="platVisible"
    :title="(isAdd ? '添加' : '修改') + '广告平台'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="584"
    dialogClass="add_plat_modal"
  >
    <div class="add_plat_wrapper">
      <a-form-model
        ref="ruleForm"
        labelAlign="left"
        class="plat_form"
        :model="formquery"
        :rules="rules"
        v-bind="layout">
        <!-- 选择区域 -->
        <a-form-model-item
          label="选择区域"
          prop="isOss"
        >
          <a-radio-group :disabled="!isAdd" name="radioGroup" v-model="formquery.isOss" @change="changeIsOss">
            <a-radio value="0"> 中国大陆 </a-radio>
            <a-radio value="1"> 海外 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <template v-if="+formquery.isOss === 0">
          <a-form-model-item
            label="广告平台"
            prop="platId"
            :rules="[{ required: true, message: '请输入广告平台', trigger: 'change' }]"
          >
            <a-select
              v-if="isAdd"
              placeholder="请选择广告平台"
              v-model="formquery.platId"
              @change="changePlat"
              :filter-option="filterOption"
              showSearch
            >
              <a-select-option v-for="item in platList" :key="item.id" :value="item.id">{{
                item.name
              }}</a-select-option>
            </a-select>
            <span v-else>{{ formquery.platName }}</span>
          </a-form-model-item>
          <!-- 账户名称 -->
          <a-form-model-item
            label="账户名称"
            prop="name"
            :rules="[{ required: true, message: '账户名称', trigger: 'blur' }]"
          >
            <a-input placeholder="请输入名称以区分不同账号" :maxLength="20" v-model.trim="formquery.name">
            </a-input>
          </a-form-model-item>
          <!-- 分成比例 -->
          <a-form-model-item
            prop="settleRatio"
            :rules="[{ required: true, message: `请输入分成比例`, trigger: 'blur' }]"
          >
            <m-tips
              slot="label"
              :content="`与预算平台约定好的分成比例，比如填写10%<br/>预算平台：${title} = 1：9`"
              title="分成比例"
              :width="200"
            ></m-tips>
            <a-input :placeholder="`请输入分成比例`" @change="changeNumberProp($event, 'settleRatio', 100)" v-model.trim="formquery.settleRatio">
              <span slot="suffix">%</span>
            </a-input>
          </a-form-model-item>
          <!-- 请求环境 -->
          <a-form-model-item
            label="请求环境"
            prop="isDebug"
            v-if="
              currentPlat.accessType !== '2' &&
                (currentPlat.id === '5' || (+currentPlat.id >= 900 && currentPlat.id !== '989'))
            "
          >
            <a-radio-group name="radioGroup" v-model="formquery.isDebug">
              <a-radio value="1"> 测试 </a-radio>
              <a-radio value="0"> 正式 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <AccountCommonForm v-model="formquery" :currentPlat="currentPlat"/>
          <PlatAccountForm :query="formquery" :platId="currentPlat.id"></PlatAccountForm>
          <PlatBQT1061 :query="formquery" :platId="currentPlat.id" :isAdd="isAdd"></PlatBQT1061>
          <!-- 状态 -->
          <a-form-model-item label="状态" prop="sts" v-if="!isAdd">
            <a-radio-group name="radioGroup" v-model="formquery.sts">
              <a-radio value="A"> 启动 </a-radio>
              <a-radio value="S"> 暂停 </a-radio>
            </a-radio-group>
            <a-alert
              v-if="formquery.sts === 'S'"
              message="暂停后，该广告平台下所有广告源状态都将关闭，再次启用时需要开发者把对应的广告源状态手动打开。因此请谨慎操作！"
              banner
            />
          </a-form-model-item>
          <SpecificPlatForm :labelSpan="labelSpan" :query="formquery" :platId="currentPlat.id"></SpecificPlatForm>
        </template>
        <template v-else>
          <!-- 广告平台 -->
          <a-form-model-item
            label="广告平台"
            prop="name"
            :rules="[{ required: true, message: '广告平台', trigger: 'blur' }]"
          >
            <a-input placeholder="请输入广告平台" :maxLength="20" v-model.trim="formquery.name">
            </a-input>
          </a-form-model-item>
          <!-- 分成比例 -->
          <a-form-model-item
            prop="settleRatio"
            :rules="[{ required: true, message: `请输入分成比例`, trigger: 'blur' }]"
          >
            <m-tips
              slot="label"
              :content="`与预算平台约定好的分成比例，比如填写10%<br/>预算平台：${title} = 1：9`"
              title="分成比例"
              :width="200"
            ></m-tips>
            <a-input :placeholder="`请输入分成比例`" @change="changeNumberProp($event, 'settleRatio', 100)" v-model.trim="formquery.settleRatio">
              <span slot="suffix">%</span>
            </a-input>
          </a-form-model-item>
          <!-- 节点请求地址 -->
          <a-form-model-item
            label="节点请求地址"
          >
            <a-checkbox v-for="node in nodeList" :key="node.id" @change="handleChangeNode(node.id)" :checked="!!formquery.sysPlatformOssNodeList.find((item) => item.node === node.id)">{{ node.name }}</a-checkbox>
          </a-form-model-item>
          <div
            class="node-requesturl-box"
            v-for="(item, index) in formquery.sysPlatformOssNodeList"
            :key="index">
            <!-- 节点 -->
            <a-form-model-item
              label="节点"
              :prop="`sysPlatformOssNodeList[${index}].node`"
              :rules="[{ required: true, message: `请选择节点`, trigger: 'change' }]"
            >
              <a-radio-group name="radioGroup" disabled v-model="item.node">
                <a-radio v-for="node in nodeList" :key="node.id" :value="node.id"> {{ node.name }} </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <!-- 请求地址 -->
            <a-form-model-item
              label="请求地址"
              :prop="`sysPlatformOssNodeList[${index}].requestUrl`"
              :rules="[{ required: true, message: `请输入请求地址`, trigger: 'blur' }]"
            >
              <a-input :placeholder="`请输入请求地址`" v-model.trim="item.requestUrl"></a-input>
            </a-form-model-item>
            <a-icon type="close-circle" class="close-icon" @click="handleDelNodeRequestUrl(index)"/>
          </div>
          <!-- 超时时长 -->
          <a-form-model-item
            label="超时时长"
            prop="respTimout"
            :rules="[{ required: true, message: `请输入超时时长`, trigger: 'blur' }]"
          >
            <a-input :placeholder="`请输入超时时长`" @change="changeNumberProp($event, 'respTimout')" v-model.trim="formquery.respTimout">
              <span slot="suffix">ms</span>
            </a-input>
          </a-form-model-item>
          <!-- Gzip -->
          <a-form-model-item
            label="Gzip"
            prop="isGzip"
          >
            <a-radio-group name="radioGroup" v-model="formquery.isGzip">
              <a-radio :value="1"> 是 </a-radio>
              <a-radio :value="0"> 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </template>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import { updatePlat, addPlat } from '@/apiForManage/advertisement'
import PlatAccountForm from '@/components/AccountModal/PlatForm'
import SpecificPlatForm from '@/components/AccountModal/SpecificPlatForm'
import PlatBQT1061 from '@/components/AccountModal/PlatBQT1061'
import AccountCommonForm from '@/components/AccountModal/CommonForm'
const labelSpan = 7
export default {
  name: 'PlatAccountModal',
  components: { PlatAccountForm, PlatBQT1061, SpecificPlatForm, AccountCommonForm },
  data () {
    return {
      nodeList: [
        {
          name: '美国',
          id: 2
        },
        {
          name: '新加坡',
          id: 1
        },
        {
          name: '其他',
          id: 0
        }
      ],
      labelSpan,
      layout: {
        labelCol: { span: labelSpan },
        wrapperCol: { span: 17 }
      },
      formquery: {
        isOss: '0',
        platId: undefined,
        name: undefined,
        mid: undefined,
        keyId: undefined,
        signKey: undefined,
        isDebug: '0',
        requestUrl: undefined,
        sysPlatformOssNodeList: [],
        accountId: undefined,
        accessKey: undefined,
        secretKey: undefined,
        sts: 'A',
        settleRatio: 0,
        respTimout: undefined,
        isGzip: 0,
        isAutoCreate: 0
      },
      currentPlat: {
        id: undefined
      },
      rules: {
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    platInfo: {
      default: () => ({}),
      type: Object
    },
    platList: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    platInfo: {
      handler (val) {
        if (this.isAdd) return
        this.formquery = JSON.parse(JSON.stringify(val))
        this.$set(this.formquery, 'sysPlatformOssNodeList', this.formquery.sysPlatformOssNodeVoList || [])
        this.currentPlat = this.platList.find((item) => {
          return +item.id === +this.formquery.platId
        })
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    platVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title
    })
  },
  methods: {
    handleChangeNode (node) {
      !this.formquery.sysPlatformOssNodeList && (this.formquery.sysPlatformOssNodeList = [])
      const index = this.formquery.sysPlatformOssNodeList.findIndex((item) => item.node === node)
      if (index > -1) {
        this.handleDelNodeRequestUrl(index)
      } else {
        this.formquery.sysPlatformOssNodeList.push({
          node: node,
          requestUrl: undefined
        })
      }
    },
    handleDelNodeRequestUrl (index) {
      this.formquery.sysPlatformOssNodeList.splice(index, 1)
    },
    changeIsOss () {
      this.$refs.ruleForm.clearValidate()
      this.formquery.name = undefined
      this.formquery.settleRatio = 0
      this.formquery.requestUrl = undefined
    },
    changeNumberProp (e, prop, max) {
      this.formquery[prop] = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
      if (!max) return
      if (+this.formquery[prop] > max) this.formquery[prop] = max
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    changePlat (val) {
      this.currentPlat = this.platList.find((item) => {
        return +item.id === +val
      })
      this.$refs.ruleForm && this.$refs.ruleForm.clearValidate()
      this.formquery.isDebug = '0'
      // 修改时,跟随platInfo参数走
      if (!this.isAdd) this.formquery.isDebug = this.platInfo.isDebug
      if (+val === 1061) {
        // 百青藤处理
        this.$set(this.formquery, 'key', 'JSON')
        this.$set(this.formquery, 'encryptType', 'ECB')
        this.$set(this.formquery, 'encryptParameter', '128')
        this.$set(this.formquery, 'codingType', 'Base64')
      } else if (+val === 1083) {
        // 汇川处理
        this.$set(this.formquery, 'key', undefined)
        this.$set(this.formquery, 'encryptType', '1')
        this.$set(this.formquery, 'encryptParameter', '1')
        this.$set(this.formquery, 'codingType', undefined)
      } else {
        this.$set(this.formquery, 'key', undefined)
        this.$set(this.formquery, 'encryptType', undefined)
        this.$set(this.formquery, 'encryptParameter', undefined)
        this.$set(this.formquery, 'codingType', undefined)
      }
    },
    handleCancel () {
      this.platVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (!this.isAdd) {
            const resp = await updatePlat(this.formquery)
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changePlat')
              this.platVisible = false
            }
          } else {
            const resp = await addPlat(this.formquery)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$emit('changePlat')
              this.platVisible = false
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.add_plat_modal .ant-modal-body {
  .add_plat_wrapper {
    padding: 0 36px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      background: fade(@primary-color, 20%);
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    .plat_form {
      .android_text {
        font-weight: 600;
      }
    }
  }
}
</style>

<style lang="less" scoped>
.node-requesturl-box{
  border: 1px solid @compBorderColor;
  border-radius: @smallRadius;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  .close-icon{
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
}
</style>
